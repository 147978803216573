import React, { useState } from "react";
import Header from "../Header";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next';

const DiwaliBlog = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, address, message } = formData;

    // Check if any required field is empty
    if (!name || !email || !address || !message) {
      toast.error("Please fill in all fields");
      return;
    }

    // Proceed with form submission logic here
    console.log("Form submitted:", formData);

    // Optional: Reset the form fields after submission
    setFormData({
      name: "",
      email: "",
      address: "",
      message: "",
    });

    toast.success("Form submitted successfully!");
  };

  return (
    <>
      <Header />
      <main>
        <section class="page-title-area-2 breadcrumb-spacing bg-theme-4 section-spacing">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xxl-9">
                <div class="page-title-wrapper-2 text-center">
                  <h1 class="page__title-2 mb-25">{t("blogPageDesc10")} </h1>
                  <div class="breadcrumb-menu-2 d-flex justify-content-center">
                    <nav
                      aria-label="Breadcrumbs"
                      class="breadcrumb-trail breadcrumbs"
                    >
                      <ul class="trail-items-2">
                        <li class="trail-item-2 trail-begin">
                          <Link to="/">
                            <span>{t("pageTitle")}</span>
                          </Link>
                        </li>
                        {/* <li class="trail-item-2 trail-center">
                          <a href="blog-classic.html">
                            <span>Blog</span>
                          </a>
                        </li> */}
                        <li class="trail-item-2 trail-end">
                          <span>{t("pageTitle8")}</span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="df-blog__area section-spacing fix"  >
          <div class="container">
            <div class="row g-5 justify-content-center">
              <div class="col-xxl-12 col-xl-7 col-lg-7 col-md-12" style={{backgroundColor:'rgba(128, 128, 128, 0.06)'}}>
                <div class="df-blog-details__wrap">
                  <div
                    class="df-blog-details__box1 mb-30 wow fadeInUp animated"
                    data-wow-duration="1.5s"
                    data-wow-delay="0.3"
                  >
                
                    <div class="df-blog-details__content mb-40">
                      <div class="df-blog-details__meta mb-25">
                        {/* <span>
                          <a href="blog-details.html">
                            <i class="fa-thin fa-circle-user"></i>By Allex Berly
                          </a>
                        </span> */}
                        <span>
                          <i class="fa-thin fa-calendar-days"></i>
                          {t("blogDetailssectionI")}{" "}
                        </span>
                        <span>
                          <a className="titleHeading">
                            <i class="fal fa-comments "></i>{" "}
                            {t("blogDetailssectionJ")}
                          </a>
                        </span>
                      </div>
                      {/* <p class="df-blog-details__text mb-20">
                        {t("blogDetails2sectionB")}
                      </p> */}
                     <div className="df-blog-details__thumb p-relative" style={{ textAlign: "center" }}>
  <img
    style={{
      height: "480px",
      objectFit: "fill",
      margin: "0 auto",
      display: "block"
    }}
    className="custumImg"
    src="assets/img/customblog/diwali1.png"
    alt="image not found"
  />
</div>


                    </div>
                  </div>
                  <div
                    class="df-blog-details__box1 mb-30 wow fadeInUp animated"
                    data-wow-duration="1.5s"
                    data-wow-delay="0.3"
                  >
                  
                    <div class="df-blog-details__content mb-40">
                      <div class="df-blog-details__meta mb-25">
                        <span className="titleHeading">
                          <i class="fa-thin fa-calendar-days"></i>
                          {t("blogDetailssectionK")}
                        </span>
                        <span>
                          {/* <a>
                            <i class="fal fa-comments"></i>{" "}
                            {t("blogDetailsComments2")}
                          </a> */}
                        </span>
                      </div>
                      <p class="df-blog-details__text mb-20">
                        {/* {t("blogDetailssectionL")} */}
                        <Trans i18nKey="blogDetailssectionL" components={{ bold: <b /> }} />

                      </p>
                      <div class="df-blog-details__thumb p-relative">
                      <div
                        data-aos="fade-up"
                        class="df-blog-details__thumb-overlay wow"
                      ></div>
                      {/* <img
                        style={{
                          height: "500px",
                          width: "100%",
                        }}
                        src="assets/img/customblog/blogc2.jpg"
                        alt="image not found"
                      /> */}
                    </div>
                      {/* <p class="df-blog-details__text mb-35">
                        {t("blogDetailsDesc2B")}
                      </p> */}
                      
                    </div>

                    <div class="df-blog-details__content mb-40">
                      <div class="df-blog-details__meta mb-25">
                        <span className="titleHeading">
                          <i class="fa-thin fa-calendar-days"></i>
                          {t("blogDetailssectionL1")}
                        </span>
                        <span>
                         
                        </span>
                      </div>
                      <p class="df-blog-details__text mb-20">
                        {/* {t("blogDetailssectionL2")} */}
                        <Trans i18nKey="blogDetailssectionL2" components={{ bold: <b /> }} />

                      </p>
                      {/* <p class="df-blog-details__text mb-35">
                        {t("blogDetailsDesc2B")}
                      </p> */}
                    </div>
                
                    <div class="df-blog-details__content mb-40">
                      <div class="df-blog-details__meta mb-25">
                        <span className="titleHeading">
                          <i class="fa-thin fa-calendar-days"></i>
                          {t("blogDetailssectionL3")}
                        </span>
                        <span>
                         
                        </span>
                      </div>
                      <p class="df-blog-details__text mb-20">
                        {/* {t("blogDetailssectionL4")} */}
                        <Trans i18nKey="blogDetailssectionL4" components={{ bold: <b /> }} />

                      </p>
                      {/* <p class="df-blog-details__text mb-35">
                        {t("blogDetailsDesc2B")}
                      </p> */}
                    </div>
                     

                  </div>
                  <div
                    class="df-blog-details__box1 mb-30 wow fadeInUp animated"
                    data-wow-duration="1.5s"
                    data-wow-delay="0.3"
                  >
                   
                    <div class="df-blog-details__content mb-40">
                      <div class="df-blog-details__meta mb-25">
                        <span className="titleHeading">
                          <i class="fa-thin fa-calendar-days"></i>
                          {t("blogDetailssectionL5")}
                        </span>
                      
                      </div>
                      
                      <p class="df-blog-details__text mb-20">
                        {/* {t("blogDetailssectionL6")} */}
                        <Trans i18nKey="blogDetailssectionL6" components={{ bold: <b /> }} />

                      </p>
                      {/* <div class="df-blog-details__thumb p-relative">
                      <div
                        data-aos="fade-up"
                        class="df-blog-details__thumb-overlay wow"
                      ></div>
                      <img
                        style={{
                          height: "480px",
                          width: "100%",
                          objectFit:'fill' ,
                          margin:'auto'
                        }}
                        src="assets/img/customblog/diwali.jpg"
                        alt="image not found"
                      />
                    </div> */}
                    <div className="df-blog-details__thumb p-relative" style={{ textAlign: "center" }}>
  <img
    style={{
      height: "480px",
      objectFit: "fill",
      margin: "0 auto",
      display: "block" ,
      width: "70%",

    }}
    className="custumImg"
    src="assets/img/customblog/diwali.jpg"
    alt="image not found"
  />
</div>
                      {/* <p class="df-blog-details__text mb-20">
                        {t("blogDetailssectionL7")}
                      </p> */}
                      {/* <p class="df-blog-details__text mb-20">
                        {t("blogDetails2sectionF2")}
                      </p> */}
                      <div class="df-blog-details__thumb p-relative">
                      <div
                        data-aos="fade-up"
                        class="df-blog-details__thumb-overlay wow"
                      ></div>
                      {/* <img
                        style={{
                          height: "500px",
                          width: "100%",
                        }}
                        src="assets/img/customblog/DSC_0128_11zon.jpg"  
                        alt="image not found"
                      /> */}
                    </div>
                    </div>

                    <div class="df-blog-details__content mb-40">
                      <div class="df-blog-details__meta mb-25">
                        <span className="titleHeading">
                          <i class="fa-thin fa-calendar-days"></i>
                          {t("blogDetailssectionL7")}
                          </span>
                        <span>
                          {/* <a>
                            <i class="fal fa-comments"></i>{" "}
                            {t("blogDetailsComments2")}
                          </a> */}
                        </span>
                      </div>
                      <p class="df-blog-details__text mb-20">
                        {/* {t("blogDetailssectionL8")} */}
                        <Trans i18nKey="blogDetailssectionL8" components={{ bold: <b /> }} />

                      </p>
                      <div class="df-blog-details__meta_updated mb-25">
                      {/* <img
                        style={{
                          height: "500px",
                          width: "100%",
                        }}
                        src="assets/img/customblog/DSC_0102_3_11zon.jpg"  
                        alt="image not found"
                      /> */}
                        
                        
                        <span>
                         
                        </span>
                      </div>
                  
                    </div>


                  
                
                    <div class="df-blog-details__content mb-40">
                      <div class="df-blog-details__meta mb-25">
                        <span className="titleHeading">
                          <i class="fa-thin fa-calendar-days"></i>
                          {t("blogDetailssectionL9")}
                        </span>
                        <span>
                         
                        </span>
                      </div>
                      <p class="df-blog-details__text mb-20">
                        {/* {t("blogDetailssectionL10")} */}
                        <Trans i18nKey="blogDetailssectionL10" components={{ bold: <b /> }} />

                      </p>
                   
                    </div>

                    <div class="df-blog-details__content mb-40">
                      <div class="df-blog-details__meta mb-25">
                        <span className="titleHeading">
                          <i class="fa-thin fa-calendar-days"></i>
                          {t("blogDetailssectionL11")}
                        </span>
                        <span>
                         
                        </span>
                      </div>
                      {/* <p class="df-blog-details__text mb-20">
                        {t("blogDetails2sectionI")}
                      </p> */}
                   
                    </div>
                     

                  </div>

                 
                </div>
                
              </div>
              <div
                    class="post-comment-form mb-40 wow fadeInUp animated"
                    data-wow-duration="1.5s"
                    data-wow-delay="0.3"
                  >
                    <div class="df-booking2__form">
                      <h3 class="df-blog-details__subtitle mb-35">
                        Leave a Reply
                      </h3>
                      <form onSubmit={handleSubmit}>
                        <div className="row gx-5">
                          <div className="col-md-6">
                            <div className="df-input-field">
                              <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Your Name"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="df-input-field">
                              <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Your Email"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="df-input-field">
                              <input
                                type="text"
                                id="address"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                placeholder="House Address"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="df-input-field">
                              <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                placeholder="Write Message Here..."
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="df-booking2__form-btn mt-0">
                              <button type="submit" className="primary-btn">
                                Send Now
                                <span className="icon__box">
                                  <img
                                    className="icon__first"
                                    src="assets/img/icon/arrow-white.webp"
                                    alt="image not found"
                                  />
                                  <img
                                    className="icon__second"
                                    src="assets/img/icon/arrow-white.webp"
                                    alt="image not found"
                                  />
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
              {/* <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-12">
                <div class="sidebar-widget mb-35">
                  <div class="blog-inner-title mb-30">
                    <h4>Search</h4>
                  </div>
                  <div class="sidebar-search p-relative">
                    <form action="#">
                      <input type="text" placeholder="Search..." />
                      <button>
                        <i class="icon-049-search"></i>
                      </button>
                    </form>
                  </div>
                </div>
                <div class="sidebar-widget mb-35">
                  <div class="blog-inner-title mb-30">
                    <h4>Recent Post</h4>
                  </div>
                  <div class="blog-sideber-meta mb-25">
                    <div class="blog-sideber-img">
                      <a href="blog-details.html">
                        <img
                          src="assets/img/blog/blog-15.webp"
                          alt="blog-meta"
                        />
                      </a>
                    </div>
                    <div class="blog-sideber-text">
                      <div class="df-blog3__meta">
                        <span class="blog-date">25 Oct 2022</span>
                      </div>
                      <h4>
                        <a href="blog-details.html">
                          The Frost Guard Conditioning
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div class="blog-sideber-meta mb-25">
                    <div class="blog-sideber-img">
                      <a href="blog-details.html">
                        <img
                          src="assets/img/blog/blog-12.webp"
                          alt="blog-meta"
                        />
                      </a>
                    </div>
                    <div class="blog-sideber-text">
                      <div class="df-blog3__meta">
                        <span class="blog-date">12 Aug 2022</span>
                      </div>
                      <h4>
                        <a href="blog-details.html">
                          Your source for all things of Fix
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div class="blog-sideber-meta mb-25">
                    <div class="blog-sideber-img">
                      <a href="blog-details.html">
                        <img
                          src="assets/img/blog/blog-14.webp"
                          alt="blog-meta"
                        />
                      </a>
                    </div>
                    <div class="blog-sideber-text">
                      <div class="df-blog3__meta">
                        <span class="blog-date">12 Oct 2022</span>
                      </div>
                      <h4>
                        <a href="blog-details.html">
                          Choosing the Right Plumbing
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="sidebar-widget mb-35">
                  <div class="blog-inner-title mb-30">
                    <h4>Category</h4>
                  </div>
                  <div class="blog-category-link">
                    <ul>
                      <li>
                        <a>Culinary Delights</a>
                      </li>
                      <li>
                        <a>Health and Nutrition</a>
                      </li>
                      <li>
                        <a>Local Ingredients</a>
                      </li>
                      <li>
                        <a>Seasonal Delights </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="sidebar-widget mb-35">
                  <div class="blog-inner-title mb-30">
                    <h4>Tags</h4>
                  </div>
                  <div class="sidebar-widget-tag">
                    <a href="blog-classic.html">Plumber</a>
                    <a href="blog-classic.html">DIYplumbing</a>
                    <a href="blog-classic.html">Fixing</a>
                    <a href="blog-classic.html">Plumbtips</a>
                    <a href="blog-classic.html">Plumbmat</a>
                    <a href="blog-classic.html">Plumb</a>
                    <a href="blog-classic.html">Plumbingpros</a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default DiwaliBlog;
