import React, { useEffect } from "react";

import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "../Pages/Home";
import Vendor from "../VendorPage/Vendor";
import AdminHome from "../Admin/AdminHome";
import AdminLogin from "../Admin/AdminLogin";
import VendorProfile from "../Admin/VendorProfile";
import Protectted from "./Protectted";
import AboutUs from "../Pages/AboutUs";
import Contact from "../Pages/Contact";
import VendorRegistretion from "../VendorPage/VendorRegistretion";
import OurFrenchice from "../Pages/OurFrenchice";
import RegistrationForm from "../Admin/RegistrationForm";
import AdminEnquiry from "../Admin/AdminEnquiry";
import Bscmegamart from "../Pages/Bscmegamart";
import Blogdetails from "../Pages/Blogdetails";
import Vendorregistration from "../Pages/Vendorregistration";
import Privacypolicy from "../Pages/Privacypolicy";
import Termsandcondition from "../Pages/Termsandcondition";
import Blog from "../Pages/Blog";
import Languagetranslator from "../Pages/Languagetranslator";
import Gallery from "../Pages/Gallery";
import Franchice from "../Admin/Franchice";
import Kargil from "../Pages/Kargil-Vijay-Diwas-2024";
import CelebratingKargil from "../Pages/Celebrating-25th-Kargil-Vijay-Diwas";
import DiwaliBlog from "../Pages/DiwaliBlog";
function ScrollToToppp() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}
function PublicRoutes() {
  return (
    <div className="">
      <BrowserRouter>
        <ScrollToToppp />
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "fixed",
              zIndex: "500",
              width: "55px",
              right: "37px",
              left: "30px",
              bottom: "30px",
            }}
          >
            <a href="https://wa.me/9325502675">
              <img
                style={{ width: "100%" }}
                src="assets/img/whtsappSqure.webp"
              />
            </a>
          </div>
          <div
            style={{
              position: "fixed",
              zIndex: "500",
              width: "55px",
              right: "70px",
              // left: "30px",
              bottom: "30px",
            }}
          >
            <Languagetranslator />
          </div>
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="/vendor" element={<Vendor />} />
          <Route path="/bscmegamart" element={<Bscmegamart />} />
          <Route path="/blog" element={<Blog />} />

          <Route path="/blogdetails" element={<Blogdetails />} />
          <Route path="/vendorreg" element={<Vendorregistration />} />
          <Route path="/privacypolicy" element={<Privacypolicy />} />
          <Route path="/termsconditions" element={<Termsandcondition />} />
          <Route path="/gallery" element={<Gallery />} />


          <Route path="/vendorregestration" element={<VendorRegistretion />} />
          <Route path="OurFrenchice" element={<OurFrenchice />} />

          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/vendorprofile" element={<VendorProfile />} />

          <Route path="/Home" element={<Protectted Component={AdminHome} />} />
          <Route
            path="/formList"
            element={<Protectted Component={RegistrationForm} />}
          />
          <Route
            path="/enquiry"
            element={<Protectted Component={AdminEnquiry} />}
          />
            <Route
            path="/franchic"
            element={<Protectted Component={Franchice} />}
          />
                    <Route path="/Kargil-Vijay-Diwas-2024" element={<Kargil />} />
                    <Route path="/Celebrating-25th-Kargil-Vijay-Diwas" element={<CelebratingKargil />} />
                    <Route path="/Bhutpurva-Sainik-Canteen-Diwali-Offers" element={<DiwaliBlog />} />


        </Routes>
        {/* <ScrollToTop /> */}
      </BrowserRouter>
    </div>
  );
}

export default PublicRoutes;
